import { template as template_3134716da0dc4470be0c634b5f8415d6 } from "@ember/template-compiler";
const FKText = template_3134716da0dc4470be0c634b5f8415d6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
